<template>
  <div class="login-wrapper">
    <div class="bg-wrapper">
      <div class="left-img">
        <img :src="loginBlockImg" alt="" />
      </div>

      <!-- 密码登录 -->
      <div class="user-wrapper" v-if="loginType == 1">
        <div class="switch_img pointer" @click="getCodeUrl(), (loginType = 2)">
          <img src="../../assets/images/login/msg-border.png" class="msg-border" />
          <div>微信扫码登录</div>
          <img src="../../assets/images/login/QRcode.png" class="code-img" />
        </div>

        <div class="title-wrapper">
          <p class="fs12" style="float: left">
            <span class="line"></span>
            <span style="margin-left: 9px">欢迎登录</span>
          </p>
          <p class="fgray fs8" style="float: left; margin-left: 9px">
            {{ oemName + '！' || 'CRM管理系统！' }}
          </p>
        </div>

        <el-form :model="loginForm" ref="loginRef">
          <el-form-item style="margin-top: 16px" prop="username" id="border-none">
            <div class="input-tips">账号</div>
            <el-input type="text" style="width: 322px" v-model="loginForm.username" placeholder="请输入手机号" />
          </el-form-item>

          <el-form-item style="margin-top: 16px" id="border-none">
            <div class="input-tips">公司</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-select v-model="companyName" size="large" remote @focus="queryCompany(loginForm.username)"
              style="width: 322px" placeholder="请选择公司" @change="selectCompany">
              <el-option style="width: 322px" v-for="item in oemLogin" :key="item.companyId" :label="item.companyName"
                :value="item.companyId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-top: 16px" prop="password" id="border-none">
            <div class="input-tips">密码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />

            <el-input :type="readonly ? 'password' : 'text'" v-model="loginForm.password" size="large"
              style="border: none" placeholder="请输入密码" ref="password">
            </el-input>
            <img src="../../assets/images/login/eye-close.svg" alt="" v-if="readonly" @click="clickEvt" class="pointer" />
            <img src="../../assets/images/login/eye-open.svg" alt="" v-else @click="clickEvt" class="pointer" />

            <!-- <el-button plain type="primary" style="width: 100px" size="large"
              @click="clearInput(loginType = 4)">忘记密码</el-button> -->
          </el-form-item>

          <el-form-item style="margin-top: 30px" align="center">
            <el-button type="primary" class="button" @click="handleLogin" @keyup.enter="keyDown(e)">登录</el-button>
          </el-form-item>
          <div class="footer">
            <span style="color: #c3c5c8">还没有账号？</span>
            <span class="pointer" style="color: #191919" @click="clearInput((loginType = 3))">立即注册</span>
            <span class="forget pointer" @click="clearInput((loginType = 4))">忘记密码？</span>
          </div>
        </el-form>
      </div>

      <!-- 扫码登录 -->
      <div class="code-wrapper" v-if="loginType == 2">
        <div class="switch_img pointer" @click="loginType = 1">
          <img src="../../assets/images/login/msg-border.png" class="msg-border" />
          <div>手机号码登录</div>
          <img src="../../assets/images/login/phone-img.png" class="code-img" />
        </div>

        <div class="title-wrapper">
          <p class="fs12" style="float: left">
            <span class="line"></span>
            <span style="margin-left: 9px">欢迎登录</span>
          </p>
          <p class="fgray fs8" style="float: left; margin-left: 9px">
            近迁CRM管理系统！
          </p>
        </div>

        <el-form :model="loginForm" :rules="loginRules" align="center" ref="loginRef">
          <iframe :src="codeUrl" name="iframe" class="w300 h400" scrolling="no" frameborder="0"></iframe>
          <!-- <div class="text2">请使用微信扫描上方二维码登录！</div> -->
        </el-form>
      </div>

      <!-- 体验登录 -->
      <!-- 账号注册 -->
      <div class="experience-wrapper" v-if="loginType == 3">
        <div class="switch_img pointer" @click="loginType = 1">
          <img src="../../assets/images/login/msg-border.png" class="msg-border" />
          <div>手机号码登录</div>
          <img src="../../assets/images/login/phone-img.png" class="code-img" />
        </div>

        <div class="title-wrapper">
          <p class="fs12" style="float: left">
            <span class="line"></span>
            <span style="margin-left: 9px">账号注册</span>
          </p>
          <p class="fgray fs8" style="float: left; margin-left: 9px">
            近迁CRM管理系统！
          </p>
        </div>

        <el-form :model="registerForm" :rules="loginRules" ref="loginRef">
          <el-form-item style="margin-top: 16px" prop="username" id="border-none" v-if="resetType == 1">
            <div class="input-tips">账号</div>
            <el-input type="text" style="width: 322px" v-model="registerForm.username" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item style="margin-top: 16px" id="border-none" v-if="resetType == 1">
            <div class="input-tips">短信验证码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-input v-model="registerForm.password" auto-complete="new-accounts" size="large"
              style="border: none; margin-right: 10px; width: 204px" placeholder="请输入验证码"></el-input>
            <el-button type="primary" :disabled="countDown !== 0 ? true : false" size="large"
              @click="getSendCode(registerForm.username)">{{
                countDown ? `重新发送(${countDown})` : "获取验证码"
              }}</el-button>
          </el-form-item>

          <el-form-item style="margin-top: 98px">
            <el-button class="button" type="primary" size="large" @click="handleLogin('test')">立即体验</el-button>
          </el-form-item>

          <div class="footer" v-if="resetType == 1">
            <span style="color: #c3c5c8">已有账号，</span>
            <span class="pointer" style="color: #191919" @click="clearInput((loginType = 1))">立即登录</span>
          </div>
        </el-form>
      </div>

      <!-- 忘记密码 -->
      <div class="reset-wrapper" v-if="loginType == 4">
        <div class="switch_img pointer" @click="loginType = 1">
          <img src="../../assets/images/login/msg-border.png" class="msg-border" />
          <div>手机号码登录</div>
          <img src="../../assets/images/login/phone-img.png" class="code-img" />
        </div>

        <div class="title-wrapper">
          <p class="fs12" style="float: left">
            <span class="line"></span>
            <span style="margin-left: 9px">忘记密码</span>
          </p>
        </div>

        <el-form :model="resetForm" ref="loginRef" class="form2">
          <el-form-item style="margin-top: 16px">
            <div class="flex-a-c">
              <div class="flex-a-c" v-for="(item, index) in resetState" :key="index">
                <div class="w24 h24 br50p be6eb flex-c-c" :class="resetType == item.type
                  ? 'bgbule cfff'
                  : resetType > item.type
                    ? 'cA2 bgfff'
                    : ''
                  " v-if="resetType <= item.type">
                  {{ index + 1 }}
                </div>
                <i class="el-icon-circle-check fs12 cblue" v-else></i>
                <p class="ml10 cA2" :class="resetType == item.type ? 'cA1 fwbold' : ''">
                  {{ item.title }}
                </p>
                <p class="mr10 ml10" v-if="index !== 2">-</p>
              </div>
            </div>
          </el-form-item>

          <!-- step1 -->
          <el-form-item style="margin-top: 16px" prop="username" id="border-none" v-if="resetType == 1">
            <div class="input-tips">账号</div>
            <el-input type="text" style="width: 322px" v-model="resetForm.username" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item style="margin-top: 16px" id="border-none" v-if="resetType == 1">
            <div class="input-tips">公司</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-select v-model="companyName" size="large" remote @focus="queryCompany(resetForm.username)"
              auto-complete="new-accounts" style="width: 322px" placeholder="请选择公司" @change="selectCompany">
              <el-option style="width: 322px" v-for="item in oemLogin" :key="item.companyId" :label="item.companyName"
                :value="item.companyId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-top: 16px" id="border-none" v-if="resetType == 1">
            <div class="input-tips">图形验证码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-input v-model="resetForm.imgCode" auto-complete="new-accounts" size="large"
              style="border: none; margin-right: 10px; width: 204px" placeholder="图形验证码"
              prefix-icon="el-icon-lock"></el-input>
            <img :src="imgCode" v-if="imgCode" @click="showResetUserInfo" class="graph w100 h40 pointer" />
            <div class="bgf7fa w100 h40" v-else></div>
          </el-form-item>
          <el-form-item style="margin-top: 16px" id="border-none" v-if="resetType == 1">
            <div class="input-tips">短信验证码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-input v-model="resetForm.messageCode" auto-complete="new-accounts" size="large"
              style="border: none; margin-right: 10px; width: 204px" placeholder="请输入验证码"
              prefix-icon="el-icon-lock"></el-input>
            <el-button type="primary" :disabled="resetCountDown !== 0 ? true : false" size="large"
              @click="getResetSendCode(resetForm.username)">{{
                resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
              }}</el-button>
          </el-form-item>

          <!-- step2 -->
          <el-form-item style="margin-top: 72px" prop="password" id="border-none" v-if="resetType == 2">
            <div class="input-tips">设置新密码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-input type="password" v-model="resetPwdForm.password" size="large" style="border: none"
              placeholder="请输入新密码" ref="password" @focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
              @click.native="clickEvt">
            </el-input>
          </el-form-item>
          <el-form-item style="margin-top: 16px" prop="password" id="border-none" v-if="resetType == 2">
            <div class="input-tips">再次输入密码</div>
            <el-input type="password" style="position: fixed; bottom: -9999px" />
            <el-input type="password" v-model="resetPwdForm.secondPassword" size="large" style="border: none"
              placeholder="请再次输入密码" ref="password">
            </el-input>
          </el-form-item>

          <el-form-item style="margin-top: 113px" id="border-none" v-if="resetType == 3">
            <div class="flex-c-c flex-column">
              <i class="el-icon-success cblue" style="font-size: 100px; color: #12222b"></i>
              <p class="cA1 fs7">设置成功!</p>
            </div>
          </el-form-item>

          <el-form-item style="margin-top: 30px" v-if="resetType == 1">
            <el-button type="primary" class="button" size="large" @click="checkNumber">
              下一步
            </el-button>
          </el-form-item>
          <el-form-item style="margin-top: 130px" v-else-if="resetType == 2">
            <el-button class="button" type="primary" size="large" @click="changePassword">下一步</el-button>
          </el-form-item>

          <el-form-item style="margin-top: 134px" v-if="resetType == 3">
            <el-button class="button" type="primary" size="large"
              @click="clearInput((loginType = 1), (resetType = 1))">立即登录</el-button>
          </el-form-item>

          <div class="footer" v-if="resetType == 1">
            <span style="color: #c3c5c8">还没有账号？</span>
            <span class="pointer" style="color: #191919" @click="clearInput((loginType = 3), (resetType = 1))">立即注册</span>
            <span class="forget pointer" @click="clearInput((loginType = 1), (resetType = 1))">返回登录>></span>
          </div>

          <div class="footer" v-if="resetType == 2">
            <span class="forget pointer" style="right: 0"
              @click="clearInput((loginType = 1), (resetType = 1))">返回上一步>></span>
          </div>
        </el-form>
      </div>
    </div>

    <p class="bottomText">
      {{ loginBottomInfo || "" }}
      <a target="_blank" style="color: white" href="https://beian.miit.gov.cn/#/Integrated/index">{{ loginDomainRecordNum
        || "" }}</a>
    </p>
  </div>
</template>

<script>
import api from "@/api/login";
import { getDataTimeSec } from "@/utils/index";
import { queryOemDomain } from "@/api/oemConfig.js";

export default {
  name: "login",

  data() {
    return {
      loginBottomInfo: "", //页面底部信息
      loginDomainRecordNum: "", //域名
      loginBlockImg: "", //登录框左侧图片
      oemName: '',//公司名称
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {},
      showLogin: "none",
      registerForm: {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      },
      resetForm: {
        username: "",
        imgCode: "",
        messageCode: "",
      },
      resetPwdForm: {
        password: "",
        secondPassword: "",
      },
      companyName: "", // 公司名
      oemLogin: [],
      loginType: 1, //登录方式 1为账号密码登录  2为 扫码登录  3为 体验账号登录 4为 忘记密码
      companyId: "",
      codeUrl: "",
      resetType: 1, //修改密码方式 1为手机号验证  2为 设置新密码  3为 成功
      imgCode: "",
      signature: "", //认证签名
      resetState: [
        {
          title: "安全验证",
          type: 1,
        },
        {
          title: "设置新密码",
          type: 2,
        },
        {
          title: "设置成功",
          type: 3,
        },
      ],
      countDown: 0,
      resetCountDown: 0,
      readonly: true,
    };
  },
  async created() {
    this.getUserInfo();
    this.showResetUserInfo();

    const oemConfig = await queryOemDomain({
      oemDomain: document.domain || "crm.jquen.com",
      companyId: 2
    });
    // const oemConfig = await queryOemDomain({ oemDomain: "crm.jquen.com" });
    if (!oemConfig) {
      this.$message.error("oem配置错误,请联系售后");
      return;
    }
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";

    if (oemConfig && oemConfig.data) {
      localStorage.setItem('oem_info', JSON.stringify(oemConfig.data));
      link.href = oemConfig.data.iconLogo;
      document.getElementsByTagName("head")[0].appendChild(link);
      //获取网页标题
      document.title = oemConfig.data.oemName;
      if (
        oemConfig.data.loginBackgroundImg &&
        oemConfig.data.loginBackgroundImg !== ""
      ) {
        document.getElementsByClassName(
          "login-wrapper"
        )[0].style.backgroundImage =
          "url(" + oemConfig.data.loginBackgroundImg + ")";
      } else {
        document.getElementsByClassName(
          "login-wrapper"
        )[0].style.backgroundImage =
          "url(https://cdn-oss.jquen.com/crm-pc/login/login_bg.png)";
      }
      this.oemName = oemConfig.data.oemName;

      this.loginDomainRecordNum = oemConfig.data.loginDomainRecordNum;
      this.loginBottomInfo = oemConfig.data.loginBottomInfo;

      if (oemConfig.data.loginBlockImg && oemConfig.data.loginBlockImg !== "") {
        this.loginBlockImg = oemConfig.data.loginBlockImg;
      } else {
        this.loginBlockImg =
          "https://cdn-oss.jquen.com/crm-pc/login/left-img.png";
      }
    } else {
      link.href = "";
      document.getElementsByTagName("head")[0].appendChild(link);
      //获取网页标题
      document.title = "CRM系统准备中";
    }
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },

  methods: {
    //密码的历史记录
    getHistory(queryString, cb) {
      let $cname = document.querySelector(".showAutocomplete");
      let history = JSON.parse(localStorage.getItem("password")),
        arr = [];
      if (history) {
        history.forEach((v, i) => {
          arr.push({
            value: v.value.username,
            password: v.value.password,
          });
        });
      }
      console.log($cname);
      arr.length == 0 ? ($cname.style.display = "none") : "";
      cb(arr);
    },
    // 查询公司
    queryCompany(val) {
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          val
        )
      ) {
        this.loginForm.username = "";
        this.resetForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: val }, { showLoading: false }).then((res) => {
        console.log(res);
        this.oemLogin = res.data;
      });
    },
    getSendCode(phone) {
      if (this.countDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.countDown = 60;
        this.timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },

    getResetSendCode(phone) {
      if (this.resetCountDown) return;
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          phone
        )
      ) {
        phone = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      api.fetchCompany({ phone: phone }, { showLoading: false }).then((res) => {
        this.resetCountDown = 60;
        this.timer = setInterval(() => {
          this.resetCountDown--;
          if (this.resetCountDown === 0) {
            clearInterval(this.timer);
          }
        }, 1 * 1000);
        console.log(res);
        this.registerForm.companyId = res.data[0].companyId;
        this.registerForm.companyName = res.data[0].companyName;
      });
      api
        .fetchVeriCode({ phone: phone, type: 1 }, { showLoading: false })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    },

    getUserInfo() {
      // let data = {
      //   code: "091LE0100dV9sL1JAp100a9sAR3LE01i",
      //   state: 'one-stand'
      // }
      // api.pcLoginCallBack(data)
      //   .then(res => {
      //     console.log(res)
      //   })
    },
    //获取验证码
    getCodeUrl() {
      api
        .fetchCodeUrl()
        .then((res) => {
          this.codeUrl = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择公司
    selectCompany(val) {
      this.companyId = val;
    },
    //获取图形验证码
    showResetUserInfo() {
      let data = {
        phone: this.resetForm.username || "",
      };
      api
        .getVerifiCode(data)
        .then((res) => {
          console.log();
          this.imgCode = "data:image/png;base64," + res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkNumber() {
      if (this.oemLogin[0].companyId == 1) {
        this.$message.error(
          "此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看"
        );
        return;
      }
      if (
        !this.resetForm.username ||
        !this.resetForm.imgCode ||
        !this.resetForm.messageCode
      ) {
        this.$message({
          message: "手机号或验证码为空",
          type: "error",
        });
        return;
      }
      if (
        !/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(
          this.resetForm.username
        )
      ) {
        this.loginForm.username = "";
        this.$message({
          title: "警告",
          message: "请输入正确的电话号码",
          type: "warning",
        });
        return;
      }
      let data = {
        imgCode: this.resetForm.imgCode,
        smsCode: this.resetForm.messageCode,
        phone: this.resetForm.username,
      };
      api
        .forgetPwd(data)
        .then((res) => {
          this.showResetUserInfo();
          this.signature = res.data;
          this.resetType = 2;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              title: "警告",
              message: err.message,
              type: "warning",
            });
          }
        });
    },
    changePassword() {
      if (
        this.resetPwdForm.password.length < 6 ||
        this.resetPwdForm.secondPassword.length < 6
      ) {
        this.$message({
          message: "密码长度不得小于六位",
          type: "warning",
        });
        return;
      }
      if (this.resetPwdForm.password !== this.resetPwdForm.secondPassword) {
        this.$message({
          message: "两次输入的密码不一，请重新输入",
          type: "warning",
        });
        return;
      }
      let data = {
        signature: this.signature,
        password: this.resetPwdForm.password,
        companyId: this.companyId,
      };
      api
        .updatePwd(data)
        .then((res) => {
          console.log(res);
          this.resetType = 3;
        })
        .catch((err) => {
          if (err.code == 201) {
            this.$message({
              message: err.message,
              type: "warning",
            });
            return;
          }
        });
    },
    // 清除登录数据
    clearInput() {
      this.loginForm = {
        username: "",
        password: "",
      };
      this.registerForm = {
        username: "",
        password: "",
        companyName: "",
        companyId: "",
      };
      this.resetForm = {
        username: "",
        imgCode: "",
        messageCode: "",
      };
      this.resetPwdForm = {
        password: "",
        secondPassword: "",
      };
      this.companyName = "";
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13 ) {
        this.handleLogin(); // 定义的登录方法
      }
    },
    // 登录请求
    handleLogin(type) {
      //登录时间
      let date = new Date();
      date = getDataTimeSec(date);
      let value = "";
      if (type !== "test") {
        //非体验用户登录
        value = {
          username: this.loginForm.username,
          phone: this.loginForm.username,
          password: this.loginForm.password,
          companyName: this.companyName,
          companyId: this.companyId,
        };
      } else {
        //体验用户登录
        value = {
          username: this.registerForm.username,
          phone: this.registerForm.username,
          // password: this.loginForm.password,
          smsCode: this.registerForm.password,
          companyName: this.registerForm.companyName,
          companyId: this.registerForm.companyId,
        };
      }

      let than = this,
        passwordArr = [], //
        text = "password";

      localStorage.removeItem("password");
      passwordArr.push({ value });
      console.log(value,'9999');

      if (JSON.parse(localStorage.getItem(text))) {
        // 判断是否已有xxx查询记录的localStorage
        if (localStorage.getItem(text).indexOf(value.username) > -1) {
          // 判断是否已有此条查询记录，若已存在，则修改顺序
          let userArr = JSON.parse(localStorage.getItem(text));
          console.log(userArr);
          userArr.forEach((v, k) => {
            console.log(v);
            if (v.value.username == value.username) {
              //将重复的username顺序提前
              let currentUser = userArr.splice(k, 1);
              userArr.unshift(currentUser[0]);
              localStorage.setItem(text, JSON.stringify(userArr));
            }
          });
        } else if (JSON.parse(localStorage.getItem(text)).length >= 5) {
          //记录大于五条时删除最后一条添加新的记录
          let arr = JSON.parse(localStorage.getItem(text));
          arr.pop();
          arr.unshift(passwordArr[0]);
          localStorage.setItem(text, JSON.stringify(arr));
        } else {
          //没有记录且不大于五条记录
          localStorage.setItem(
            text,
            JSON.stringify(
              passwordArr.concat(JSON.parse(localStorage.getItem(text)))
            )
          );
        }
      } else {
        // 首次创建
        localStorage.removeItem("password");
        localStorage.setItem(text, JSON.stringify(passwordArr));
      }
      if (type !== "test") {
        //手机密码登录
        if (value.companyId == 1) {
          this.$message.error(
            "此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看"
          );
          return;
        }
        if (!value.username || !value.companyName || !value.password) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }

        api
          .fetchLoginByPhone(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = res.data.lastLoginTime;
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      } else {
        //体验账号登录
        if (!value.username || !value.smsCode) {
          this.$message.error("账号信息不完整，请填写完整");
          return;
        }
        api
          .fetchLogin(value)
          .then((res) => {
            if (res.code === 200) {
              //存储登录用户信息
              this.$store.state.loginRoot.loginDate = date;
              this.$store.state.loginRoot.userInfo = res.data;
              localStorage.setItem("info", JSON.stringify(res.data));
              localStorage.setItem("loginDate", res.data.lastLoginTime);
              //存储token
              localStorage.setItem("token", res.data.token);
              this.$router.push("/dashboard");
            } else {
              this.$message.error(res.data);
              return;
            }
          })
          .catch((err) => {
            this.$message.error(err.message);
          });
      }
    },

    changAttr(e, type) {
      // if (type === "focus") {
      //   if (e) {
      //     e.stopPropagation();
      //     e.preventDefault();
      //   }
      //   setTimeout(() => {
      //     this.readonly = false;
      //   }, 100);
      // } else {
      //   if (e) {
      //     e.stopPropagation();
      //   }
      //   this.readonly = true;
      // }
    },
    clickEvt() {
      // if (this.$refs.password) {
      //   this.$refs.password.$refs.input.onmousedown = (evt) => {
      //     if (evt) {
      //       evt.preventDefault();
      //       evt.stopPropagation();
      //     }
      //     if (this.loginForm.password === "" || this.readonly) {
      //       this.$refs.password.$refs.input.blur();
      //       setTimeout(() => {
      //         this.$refs.password.$refs.input.focus();
      //       }, 0);
      //     }
      //     return false;
      //   };
      // }
      this.readonly = !this.readonly;
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.login-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // background-image: url("../../assets/images/login/login_bg.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.bg-wrapper {
  width: 951px;
  height: 713px;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 20px rgba(81, 205, 203, 0.08);
  opacity: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  transition: all 0.4s;
  position: relative;
}

.switch_img {
  position: absolute;
  top: 0;
  right: 0;

  div {
    position: absolute;
    width: 72px;
    height: 18px;
    right: 66px;
    top: 24px;
    font-size: 12px;
    line-height: 18px;
    color: #12222b;
  }

  .msg-border {
    position: absolute;
    top: 18px;
    right: 56px;
    width: 84px;
    height: 30px;
  }

  .code-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
  }
}

.bg-wrapper:hover {
  box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);
  transition: all 0.4s;
}

.bg-wrapper .el-input__inner {
  border: none;
}

.left-img {
  width: 439px;
  height: 665px;
  margin: 24px 26px;
  background: #163949;
  box-shadow: 0px 4px 15px rgba(32, 92, 247, 0.15);
  border-radius: 6px;

  img {
    width: 439px;
    height: 100%;
    mix-blend-mode: luminosity;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    pointer-events: none;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.user-wrapper {
  height: 501px;
  margin-top: 113px;
  margin-left: 56px;
}

.title-wrapper {
  width: 158px;
  height: 66px;

  p {
    position: relative;
    margin-bottom: 6px;
  }

  span {
    height: 20px;
    line-height: 20px;
  }

  .line {
    position: absolute;
    display: inline-block;
    width: 3px;
    height: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #12222b;
  }
}

.el-form {
  margin-top: 48px;
  position: relative;

  ::v-deep .el-input__inner {
    height: 41px !important;
  }

  .input-tips {
    color: #3d3c49;
  }

  img {
    position: absolute;
    top: 46px;
    right: 10px;
  }

  .button {
    width: 322px;
    height: 45px;
    padding: 12px 10px;
    background: linear-gradient(118.65deg, #12222b 10.07%, #174757 91.7%);
    border-radius: 34px;
  }

  .footer {
    width: 322px;
    font-size: 12px;
    line-height: 18px;
    color: #3d3c49;

    .forget {
      position: absolute;
      margin-left: 142px;
    }
  }
}

.bottomText {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 32px;
  left: 0;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
}

.opacity0 {
  opacity: 1;
  transition: all 0.2s;
}

.opacity1 {
  opacity: 1;
  transition: all 0.2s;
}

.code-wrapper {
  @extend .user-wrapper;

  .qrCode {
    position: absolute;
    top: 7px;
    left: 0;
    width: 302px;
    height: 302px;
  }

  .text2 {
    position: absolute;
    top: 321px;
    left: 0;
    width: 240px;
    height: 21px;
    font-size: 16px;
    line-height: 21px;
    color: #12222b;
  }
}

.experience-wrapper {
  @extend .user-wrapper;

  ::v-deep .el-form {
    margin-top: 80px;
  }
}

.reset-wrapper {
  @extend .user-wrapper;

  .bgbule {
    background-color: #12222b !important;
  }

  .flex-a-c {
    font-size: 12px;
  }

  .title-wrapper {
    height: 36px;
  }

  ::v-deep .el-form {
    margin-top: -10px;
  }

  .graph {
    position: static;
  }
}
</style>
